/** @format */

import React, { useRef, useState } from "react";
import Typewriter from "typewriter-effect";
import "./animated-intro.scss";

export interface IProps {
  onClickNext?: () => void;
}

export const AnimatedIntro: React.FC<IProps> = ({ onClickNext }) => {
  const [hideCursor, setHideCursor] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const nextRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={`animated-intro ${hideCursor ? "hide-cursor" : ""}`}>
      <Typewriter
        onInit={(typewriter) => {
          typewriter
            .changeDelay(50)
            .typeString("Sorry, no pancakes to purchase on this site.")
            .pauseFor(1000)
            .deleteAll(10)
            .typeString("We help make products that sell...")
            .pauseFor(1000)
            .deleteChars(7)
            .typeString('"sell like hotcakes".')
            .pauseFor(1000)
            .deleteAll(10)
            .typeString("Welcome to <br />")
            .callFunction(() => setHideCursor(true))
            .typeString("<h1>Hotcake<br />Generator </br>Inc!</h1>")
            .callFunction(() => {
              setShowNext(true);
            })
            .pauseFor(100)
            .callFunction(() => {
              nextRef?.current?.scrollIntoView({ behavior: "smooth" });
            })
            .start();
        }}
      />
      {showNext && (
        <button ref={nextRef} className="btn btn-link" onClick={onClickNext}>
          Next &gt;&gt;
        </button>
      )}
    </div>
  );
};
