/** @format */

import React from "react";
import Logo from "../../assets/logo.svg";

export interface IProps {}

export const HotCakeNavbar: React.FC<IProps> = () => {
  // const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  //
  // // const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-xxl">
        <a className="navbar-brand font-weight-bolder" href="/">
          <img
            src={Logo}
            alt="Logo"
            width="72"
            height="72"
            className="vertical-align-middle text-white"
          />
        </a>
        {/*<button*/}
        {/*  className="custom-toggler navbar-toggler"*/}
        {/*  type="button"*/}
        {/*  data-toggle="collapse"*/}
        {/*  data-target="#navbarToggle"*/}
        {/*  aria-controls="navbarToggle"*/}
        {/*  aria-expanded={!isNavCollapsed ? true : false}*/}
        {/*  aria-label="Toggle navigation"*/}
        {/*  onClick={handleNavCollapse}*/}
        {/*>*/}
        {/*  <span className="navbar-toggler-icon"></span>*/}
        {/*</button>*/}

        {/*<div*/}
        {/*  className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}*/}
        {/*  id="navbarToggle"*/}
        {/*>*/}
        {/*  <a className="nav-link text-info" href="/products">*/}
        {/*    Products*/}
        {/*  </a>*/}
        {/*  <a className="nav-link text-info" href="/blog">*/}
        {/*    Blog*/}
        {/*  </a>*/}
        {/*  <a href="/login" className="btn btn-sm btn-info nav-link text-white">*/}
        {/*    Login*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>
    </nav>
  );
};
