/** @format */

import React from "react";
import "./animated-intro.scss";
import { WithModal } from "../WithModal";
import { AnimatedIntro } from "./AnimatedIntro";

export const AnimatedIntroModal: any = WithModal(
  ({
    ModalContent,
    closeModal,
    showModal,
  }: {
    ModalContent: any;
    closeModal: any;
    showModal: any;
  }) => {
    return (
      <>
        <ModalContent showCloseIcon fullscreen>
          <AnimatedIntro onClickNext={closeModal} />
        </ModalContent>
      </>
    );
  }
);
