import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import {Landing} from "./pages/landing/Landing";


function App() {
  return (
      <Router>
        <Route path="/" exact={true} component={Landing} />
      </Router>
  );
}

export default App;
