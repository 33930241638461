/** @format */

import React from "react";

export const WithModal = (ComposedComponent: any) => {
  class ModalWrapperComponent extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
      this.state = { modalOpen: !!props.modalOpen };
      this.showModal = this.showModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.modalWrap = this.modalWrap.bind(this);
    }
    showModal() {
      this.setState({ modalOpen: true });
    }
    closeModal() {
      this.setState({ modalOpen: false });
    }

    modalWrap({
      children,
      showCloseIcon = true,
      fullscreen = false,
    }: {
      children: any;
      showCloseIcon: boolean;
      fullscreen: boolean;
    }) {
      return (
        <div
          className={`modal fade ${this.state.modalOpen ? "show" : ""}`}
          style={this.state.modalOpen ? { display: "block" } : {}}
          aria-hidden="true"
        >
          <div
            className={`modal-dialog ${fullscreen ? "modal-fullscreen" : ""}`}
          >
            <div className="modal-content">
              <div className="modal-header">
                {showCloseIcon && (
                  <button
                    type="button"
                    className="btn-close"
                    onClick={this.closeModal}
                    aria-label="Close"
                  />
                )}
              </div>
              <div className="modal-body">{children}</div>
            </div>
          </div>
        </div>
      );
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          showModal={this.showModal}
          closeModal={this.closeModal}
          ModalContent={this.modalWrap}
        />
      );
    }
  }
  return ModalWrapperComponent;
};
