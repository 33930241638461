/** @format */

import React from "react";
import { HotCakeNavbar } from "./navbar/HotcakeNavbar";

export interface IProps {
  children: any;
}

export const NavAndFooter: React.FC<IProps> = ({ children }) => (
  <article>
    <header>
      <HotCakeNavbar />
    </header>
    <div className="container-xxl pt-3">{children}</div>
    <footer>{/*TODO add contact and other info*/}</footer>
  </article>
);
