/** @format */

import React from "react";
import { NavAndFooter } from "../../layouts/NavAndFooter";
import { AnimatedIntroModal } from "../../components/animated-intro/AnimatedIntroModal";
import "./landing.scss";

export interface PageProps {}

export const Landing: React.FC<PageProps> = () => (
  <NavAndFooter>
    <section className="landing p-5">
      <p>
        Hotcake Generator is currently incubating several technology products.
      </p>
      <p>
        We also offer consulting services.{" "}
        <a href="mailto:info@hotcakegenerator.com">Contact us</a> for more
        details.
      </p>
    </section>
    <AnimatedIntroModal modalOpen />
  </NavAndFooter>
);
